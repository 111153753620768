import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", { staticClass: "create-link-modal", attrs: { "title": "Link Item Code", "after-close": _vm.afterModalClose, "width": 520 }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
    return [_c("a-button", { key: "cancel", on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { key: "submit", attrs: { "type": "primary", "loading": _vm.isLoading, "disabled": !_vm.selectedItem || _vm.selectedItem.length === 0 }, on: { "click": _vm.onSave } }, [_vm._v(" Create Link "), _vm.selectedItem && _vm.selectedItem.length > 0 ? _c("span", { staticClass: "ml-1" }, [_vm._v("(" + _vm._s(_vm.selectedItem.length) + ")")]) : _vm._e()])];
  }, proxy: true }]), model: { value: _vm.visible, callback: function($$v) {
    _vm.visible = $$v;
  }, expression: "visible" } }, [_c("div", { staticClass: "create-link-modal-content" }, [_c("label", [_vm._v("Select Item Code to Link")]), _c("a-select", { staticClass: "w-100", attrs: { "mode": "multiple", "form-item": "", "rules": "required", "placeholder": "Start filtering with 'F'", "option-label-prop": "label" }, on: { "search": _vm.fetchData }, model: { value: _vm.selectedItem, callback: function($$v) {
    _vm.selectedItem = $$v;
  }, expression: "selectedItem" } }, [_vm.fetching ? _c("a-spin", { attrs: { "slot": "notFoundContent", "size": "small" }, slot: "notFoundContent" }) : _vm._e(), _vm._l(_vm.items, function(item) {
    return _c("a-select-option", { key: item.itemCode, attrs: { "label": item.itemCode } }, [_c("a-row", [_c("a-col", { attrs: { "span": 8 } }, [_c("span", { staticClass: "text--gray-10" }, [_vm._v(_vm._s(item.itemCode))])]), _c("a-col", { attrs: { "span": 16 } }, [_c("span", { staticClass: "text--gray-8" }, [_vm._v(_vm._s(item.description))])])], 1)], 1);
  })], 2)], 1)]);
};
var staticRenderFns$1 = [];
var CreateLink_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".create-link-modal[data-v-e979f732] .ant-modal-title{font-weight:500}.text--gray-10[data-v-e979f732]{color:#272d35}.text--gray-8[data-v-e979f732]{color:#5c6c7f}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateLink",
  inject: ["resourceProps", "itemApiUrl"],
  data() {
    const [groupProps, itemProps, itemMastersProps, listItemProps] = this.resourceProps;
    const commonApiUrl = apiUrl$1 + `/sterilization/common/item-masters`;
    return {
      apiUrl: apiUrl$1,
      commonApiUrl,
      groupProps,
      itemProps,
      itemMastersProps,
      listItemProps,
      isLoading: false,
      visible: true,
      fetching: false,
      items: [],
      selectedItem: []
    };
  },
  computed: {
    groupEntity() {
      return this.groupProps.crud.getEntity() || {};
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    genQuery(keyword) {
      return `fe=(ItemCode contains \`${keyword}\`)&isBpcsOnly=true`;
    },
    async onSave() {
      const { auditCycleId } = this.groupEntity;
      this.visible = false;
      this.isLoading = true;
      this.storeValue("ItemCodes", this.selectedItem);
      this.storeValue("AuditCycleId", auditCycleId);
      try {
        await this.itemProps.crud.submitEntity("create", this.itemApiUrl);
      } finally {
        await this.listItemProps.crud.fetchList(`${apiUrl$1}/sterilization/audit-groups/${this.$route.params.id}/items`);
        this.isLoading = false;
      }
    },
    storeValue(resourceKey, value) {
      this.itemProps.crud.updateEntity("createForm", { resourceKey, value });
    },
    cancel() {
      this.visible = false;
    },
    async fetchData(keyword = "") {
      this.fetching = true;
      const query = this.genQuery(keyword);
      const res = await this.axios.get(`${this.commonApiUrl}?${query}`);
      if (res && res.data && res.data.results) {
        [...this.items] = res.data.results;
      }
      this.fetching = false;
    },
    afterModalClose() {
      this.$router.push(this.itemProps.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "e979f732", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateLink = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-link" }, [_c("resource", { attrs: { "name": [
    "sterilization.audit-groups",
    "sterilization.audit-groups.items",
    "sterilization.common.item-masters",
    "sterilization.audit-groups.items.list"
  ], "resource-id-name": ["id", "id", "itemCode"], "api-url": _vm.apiUrl, "redirect-route": [
    "/sterilization-audits/audit-groups",
    _vm.redirectRoute,
    _vm.redirectRoute
  ] } }, [_c("create-link")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateLinkIndex",
  inject: ["groupId"],
  components: {
    CreateLink
  },
  data() {
    return {
      apiUrl,
      redirectRoute: `/sterilization-audits/audit-groups/${this.groupId}`
    };
  },
  provide() {
    return {
      itemApiUrl: `${this.apiUrl}/sterilization/audit-groups/${this.groupId}/items`
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
